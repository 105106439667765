<template>
    <ag-grid-vue style="width: 500px; height: 500px;"
                 class="ag-theme-material"
                 :columnDefs="columnDefs"
                 :rowData="rowData"
    >
    </ag-grid-vue>
</template>


<script>
import { AgGridVue } from "ag-grid-vue"
export default {
    data() {
        return {
            columnDefs: null,
            rowData: null
        }
    },
    components: {
        AgGridVue
    },
    beforeMount() {
        this.columnDefs = [
            {headerName: 'Make', field: 'make', sortable: true, filter: true },
            {headerName: 'Model', field: 'model', sortable: true, filter: true },
            {headerName: 'Price', field: 'price', sortable: true, filter: true }
        ]

        this.rowData = [
            {make: 'Toyota', model: 'Celica', price: 35000},
            {make: 'Ford', model: 'Mondeo', price: 32000},
            {make: 'Porsche', model: 'Boxter', price: 72000}
        ]
    }
}
</script>

<style lang="scss">
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";
</style>
