import Vue from 'vue'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';

import { AgGridVue } from "ag-grid-vue"
import 'ag-grid-enterprise'
import './styles/ag-roots.css'
import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";

import HelloWorld from "./components/HelloWorld"
Vue.component('hello-world', HelloWorld)
Vue.component('ag-grid-vue', AgGridVue)


if (typeof window !== 'undefined' && window.Vue) {
    Vue.config.productionTip = false
    Vue.use(Vuetify)
    window.vuetify = Vuetify
}

